import {
  useDownloadQuotePdf,
  useShowDocumentDownloadOkPopup,
  useShowErrorPopup,
} from '../../../hooks';
import styles from './item-card.module.css';
import classNames from 'classnames/bind';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { PatientQuote } from 'src/ts';
import { FC, useState } from 'react';
import { Button } from '../..';

const cx = classNames.bind(styles);

interface ItemCardProps {
  patientQuote: PatientQuote;
}

export const ItemCard: FC<ItemCardProps> = ({ patientQuote }) => {
  const { t } = useTranslation();
  const { downloadQuotePdf, isLoading: isDownloadingQuotePdf } = useDownloadQuotePdf();
  const { showDocumentDownloadOkPopup } = useShowDocumentDownloadOkPopup();
  const { showErrorPopup } = useShowErrorPopup();
  const [isDownloading, setIsDownloading] = useState(false);

  const parseEstadoDePago = (estado: string): string => {
    if (estado !== null) return estado;
    return '-';
  };

  const parseTipoDePago = (type: string): string => {
    if (type !== null) return type;
    return '-';
  };

  const handleDownloadPdf = async (quoteId: string) => {
    setIsDownloading(true);
    downloadQuotePdf(
      { quoteId },
      {
        onSuccess: () => showDocumentDownloadOkPopup(),
        onError: error => showErrorPopup(`errors.${error.message}`),
        onSettled: () => setIsDownloading(false),
      },
    );
  };

  return (
    <div className={cx('item-card--container', 'bs-20')}>
      <div className={cx('item-card--container--header')}>
        <div>
          <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.treatmentOf'))}:</strong>

          <p>{patientQuote?.Subject}</p>
        </div>
      </div>

      <div className={cx('item-card--container--body')}>
        <div className={cx('details-container')}>
          <div>
            <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.amount'))}:</strong>
            <p>{patientQuote.Grand_Total} EUR</p>
          </div>

          <div>
            {/* TODO: Show date of acceptance */}
            <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.acceptedDate'))}:</strong>
            <p>{patientQuote.fechaAceptacion}</p>
          </div>
        </div>

        <div className={cx('details-container')}>
          <div>
            <p>
              <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.paymentType'))}: </strong>
            </p>
            <p>{parseTipoDePago(patientQuote?.Tipo_de_Pago)}</p>
          </div>

          {/* <div>
            <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.state'))}: </strong>
            <p>{parseEstadoDePago(patientQuote?.Estado_del_pago)}</p>
          </div> */}
        </div>
      </div>

      <Button
        className='informe-button'
        onClick={() => handleDownloadPdf(patientQuote.id)}
        disabled={isDownloadingQuotePdf}
        isLoading={isDownloadingQuotePdf && isDownloading}
        fullWidth={true}
      >
        {GlobalService.uppercaseFirstLetter(t('pages.reports.detailedReport'))}
      </Button>
    </div>
  );
};
