import { LoginSignupLayout, SignupForm0, MobileVerificationForm } from '../../components';
import { useState } from 'react';
import {
  useBlindSignupToken,
  useMobileVerificationIntervals,
  useSetSession,
  useSignup,
} from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { AcceptedTermsData } from 'src/ts/interfaces/user';

export const Signup = () => {

  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [acceptedCommercialTerms , setAcceptedCommercialTerms] = useState(false);
  const [acceptedDataProtectionPolicy , setAcceptedDataProtectionPolicy] = useState(false)
  const [acceptedTermsData, setAcceptedTermsData] = useState<AcceptedTermsData>({acceptedCommercialTerms: acceptedCommercialTerms, acceptedDataProtectionPolicy:acceptedDataProtectionPolicy})
  useMobileVerificationIntervals();
  const { signupAsync, error: signupError, clear: clearSignup } = useSignup();
  const { setSession } = useSetSession();
  const navigate = useNavigate();
  const { cleanBstInfo, verifyPatientBirthDate } = useBlindSignupToken();

  const [currentForm, setCurrentForm] = useState(0);

  const moveToForm0 = () => {
    setCurrentForm(0);
  };

  const moveToForm1 = () => {
    setCurrentForm(1);
  };

  const handleRegister = async (smsCode: string) => {
    try {
      await signupAsync(
        { 
          mobile: mobile, 
          password, 
          smsCode: smsCode, 
          verifyPatientBirthDate, 
          acceptedTermsData: {
            acceptedCommercialTerms, 
            acceptedDataProtectionPolicy
          }
        },
        {
          onSuccess: response => {
            cleanBstInfo();
            setSession(response.auth, response.userInfo);
            if(response.blindSignup?.contactId){
              navigate(`/informes/${response.blindSignup.contactId}`);
              return;
            }
            navigate('/personas');
          },
          onError: error => {
            console.error('error', error);
          },
        },
      );
    } catch (error: any) {
      console.error('error', error);
    }
  };
    
    return (
    <LoginSignupLayout type={currentForm === 0 ? 'signup' : 'signupVerifyMobile'}>
      {currentForm === 0 ? (
        <SignupForm0
          initialValues={{ mobile, password, passwordConfirmation, acceptedTermsData, acceptedCommercialTerms, acceptedDataProtectionPolicy }}
          setInitialValues={{ setMobile, setPassword, setPasswordConfirmation, setAcceptedCommercialTerms, setAcceptedDataProtectionPolicy, setAcceptedTermsData }}
          handleSubmit={moveToForm1}
        />
      ) : (
   
        <MobileVerificationForm
          mobile={mobile}
          goBack={moveToForm0}
          handleSubmit={handleRegister}
          clearSubmitError={clearSignup}
          submitError={signupError?.message}
        />

      )}

    </LoginSignupLayout>
  );
};
