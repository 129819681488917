import axios from 'axios';
import { ApiResponse, MediaDownloadData } from 'src/ts';

export const downloadQuoteMediaService = async (files: MediaDownloadData[]) => {
  const response = await axios.post<ApiResponse<Record<string, string>>>(
    '/patients/download-quote-media',
    { files },
  );

  const data = response.data;

  if (data.isOk !== true) {
    throw new Error(data.message);
  }

  return data.data;
};
