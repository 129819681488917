import styles from './confirm-quote.module.css';
import classNames from 'classnames/bind';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import AddressBlock from '../../components/AddressBlock';
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import { GoBackArrow, TopMenu } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../../styles/InformeDetallado/InformeDetallado.css';

// Hooks
import {
  useViewport,
  useSelectedPatient,
  useSelectedQuote,
  useGetQuoteProducts,
  useShowQuoteProductsError,
  useShowErrorPopup,
  useShowEditTreatmentsPopup,
  useUserAddress,
  useUpdateQuote,
} from '../../hooks';

// Images
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GreenCheck from '../../img/greenCheck.png';
import GrayCheck from '../../img/grayCheck.png';
import LeftArrowSmall from '../../img/PersonaImg/left-arrow-short.png';
import { useGetPatientDeals } from '../../hooks/patients/use-get-patient-deals';
import { Button } from '../../components';
import { Quote } from 'src/ts/interfaces/quotes';
import { PatientDeal } from 'src/ts';

const cx = classNames.bind(styles);

interface IReportArticle {
  description?: string;
  id?: string;
  isSelected?: boolean;
  isShowingDescription: boolean;
  name?: string;
  price?: number;
}

export const ConfirmQuote = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, reportId } = useParams();
  const { selectedPatient } = useSelectedPatient();
  const { selectedQuote } = useSelectedQuote();
  const { getQuoteProducts, isLoading: isLoadingQuoteProducts } = useGetQuoteProducts();
  const { getPatientDeals, isLoading: isLoadingPatientDeals } = useGetPatientDeals();
  const { showQuoteProductsError } = useShowQuoteProductsError();
  const { showErrorPopup } = useShowErrorPopup();
  const { showEditTreatmentPopup } = useShowEditTreatmentsPopup();
  const { userAddress } = useUserAddress();
  const { updateQuote } = useUpdateQuote();

  /***********
   *  States *
   ***********/
  const [quoteProducts, setQuoteProducts] = useState<IReportArticle[]>([]);
  const [total, setTotal] = useState<number>(0);
  const { viewportWidth } = useViewport();
  const [ quote, setQuote ] = useState<Quote>();
  const [ isSignedConsent, setIsSignedConsent ] = useState<boolean>(false);
  const [ deal, setDeal ] = useState<PatientDeal | null>(null);
  const [ acceptedQuoteText, setAcceptedQuoteText ] = useState<string>('');
  const [ isQuoteAccepted, setIsQuoteAccepted ] = useState<boolean>(false);
  const [ isQuotePaid, setIsQuotePaid ] = useState<boolean>(false);

  /**********
   *  Hooks *
   **********/
  useEffect(() => {
    handleGetQuoteProducts();
    handleGetPatientDeals();
  }, [selectedPatient]);

  useEffect(() => {
    calcAndSetTotal();
  }, [quoteProducts]);

  useEffect(() => {
    setAcceptedQuoteText( isQuoteAccepted ? GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.quoteAccepted')) : '');
  }, [isQuoteAccepted])

  // const isQuoteAccepted = () => {
  //   return quote?.Quote_Stage === 'Presupuesto aceptado';
  // }

  /*************
   *  Handlers *
   *************/
  const handleCheckToggle = (article: IReportArticle) => {
    if (isQuoteAccepted) return;
    article.isSelected = !article.isSelected;
    const resultArticles: IReportArticle[] = [];
    quoteProducts.forEach(item => {
      resultArticles.push(item.id === article.id ? article : item);
    });
    setQuoteProducts(resultArticles);
  };

  const handleCheckTreatments = () => {
    const control = quoteProducts.filter((item: IReportArticle) => !item.isSelected);
    if (control.length === quoteProducts.length) {
      showErrorPopup('errors.treatmentNotSelected');
      return;
    }
    if (control.length) {
      showEditTreatmentPopup();
      return;
    }
    if (
      userAddress.Ciudad === '' ||
      userAddress.Codigo_postal === '' ||
      userAddress.Direcci_n === '' ||
      userAddress.Pa_s === ''
    ) {
      showErrorPopup('errors.addressesNotFound');
      return;
    }
  };

  const handleShowDetails = (article: IReportArticle) => {
    article.isShowingDescription = !article.isShowingDescription;
    const resultArticles: IReportArticle[] = [];

    quoteProducts.forEach(item => {
      if (item.id === article.id) {
        resultArticles.push(article);
      } else {
        resultArticles.push(item);
      }
    });
    setQuoteProducts(resultArticles);
  };

  /***********
   * Helpers *
   ***********/
  const calcAndSetTotal = (newArticles?: IReportArticle[]) => {
    const articles: IReportArticle[] | undefined = quoteProducts.length
      ? quoteProducts
      : newArticles;
    let total = 0;
    if (articles?.length) {
      articles.forEach((article: IReportArticle) => {
        if (article.isSelected && article.price) total += article.price;
      });
    }
    setTotal(total);
    window.localStorage.setItem(
      'tot',
      total.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }),
    );
  };

  const handleGetQuoteProducts = () => {
    if (selectedQuote == null) return;

    getQuoteProducts(
      { quoteId: selectedQuote.id },
      {
        onSuccess: ({ formattedQuoteProducts, quote }) => {
          const hideDescriptionQuotes = formattedQuoteProducts.map(formattedQuoteProduct => ({
            ...formattedQuoteProduct,
            isShowingDescription: false,
          }));
          setQuoteProducts(hideDescriptionQuotes);
          setQuote(quote);
          setIsQuoteAccepted(quote.Quote_Stage === 'Presupuesto aceptado');
        },
        onError: error => {
          showQuoteProductsError(error.message);
        },
      },
    );
  };

  const handleGetPatientDeals = () => {
    getPatientDeals(
      {
        patientId: selectedPatient.id,
      },
      {
        onSuccess: deal => {
          setDeal(deal[selectedPatient.id][0]);
          if ((deal[selectedPatient.id][0].Pagos?.data.length ?? 0) > 0) setIsQuotePaid(true);
          setIsSignedConsent((deal[selectedPatient.id][0].Archivo_Consentimientos?.length ?? 0) > 0 ? true : false);
        },
      },
    );
  }

  const handleClickButton = () => {
    if(deal) {
      if (isQuotePaid) return;
      if (!quote?.Accepted_In_App_Consumer) updateQuote.acceptedInAppConsumer(quote?.id as string, true);
      if (isSignedConsent) {
        navigate(`/informeDetallado/${patientId}/${reportId}/paymentMethod`);
      } else {
        navigate(`/informeDetallado/${patientId}/${reportId}/firmaConsentimiento`);
      }
      handleCheckTreatments();
    }
  };



  const isDisabledIfPaid = () => isQuotePaid;

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;

  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.mainTitle'))}:`;

  const addresBlock = (
    <AddressBlock
      isScreenBig={isScreenBig}
      title={GlobalService.uppercaseFirstLetter(t('pages.tienda.billingAddress'))}
    />
  );

  const formattedDetails = quoteProducts.map((article: IReportArticle, index: number) => (
    <div key={index} className={`p-2 ${index !== 0 ? 'border-t-2' : ''}`}>
      <div className='flex justify-between'>
        <div className='flex gap-2'>
          <div
            className={`relative w-5 h-5 border-2 rounded border-black ${isQuoteAccepted ? '' :  'cursor-pointer'}`}
            onClick={() => {
              handleCheckToggle(article);
            }}
          >
            {article.isSelected ? (
              <>
                <div className='absolute w-1.5 h-1.5 bg-white checkbox-white-properties'></div>
                <img src={isQuoteAccepted ? GrayCheck : GreenCheck} alt='Check' className='absolute checkbox-check-properties' />
              </>
            ) : null}
          </div>

          <p>
            <strong>{article.name}</strong>
          </p>
        </div>

        <div className='flex flex-row items-center gap-4'>
          <p>{article.price}€</p>
          {article.isShowingDescription && article.description?.length ? (
            <FontAwesomeIcon
              className='cursor-pointer'
              icon={faAngleUp}
              onClick={() => handleShowDetails(article)}
            />
          ) : !article.isShowingDescription && article.description?.length ? (
            <FontAwesomeIcon
              className='cursor-pointer'
              icon={faAngleDown}
              onClick={() => handleShowDetails(article)}
            />
          ) : null}
        </div>
      </div>
      {article.isShowingDescription ? (
        <div className='py-3'>
          <p className='text-xs text-slate-400'>{article.description}</p>
        </div>
      ) : null}
    </div>
  ));

  const buttonLine = (
    <div className={cx('buttons-container')}>
      <Button
        styleType='primary'
        onClick={handleClickButton}
        disabled={isDisabledIfPaid()}
        fullWidth={true}
        isLoading={isLoadingPatientDeals}
      >
        { 
        isDisabledIfPaid() ?
          GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.processFinished'))
          :
            isQuoteAccepted ?
              isSignedConsent ?
                GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.pendingPayment'))
              :
                GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.signConsent'))
            :
              GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.acceptAndContinue'))
        }
      </Button>
    </div>
  );

  const totalLine = (
    <div className='flex justify-between mt-4'>
      <p>
        <strong>{t('total').toUpperCase()}</strong>
      </p>
      <p>
        <strong>{total}€</strong>
      </p>
    </div>
  );

  const goBackLink =
    selectedQuote == null || selectedQuote.media.length <= 0
      ? `/informes/${patientId}`
      : `/informeDetallado/${patientId}/${selectedQuote?.id}`;

  // Desktop values
  const desktopGoBackArrow = (
    <div className='my-auto'>
      <GoBackArrow to={goBackLink} />
    </div>
  );

  // Responsive values
  const responsivePageTitle = GlobalService.uppercaseFirstLetter(
    t('pages.reports.responsiveMainTitle'),
  );

  if (isLoadingQuoteProducts) {
    return <RegularSpinner />;
  }

  return (
    <div className={cx('main-container')}>
      <TopMenu
        title={isScreenBig ? pageTitle : responsivePageTitle}
        userName={isScreenBig ? selectedPatient.title : undefined}
        leftIcon={isScreenBig ? undefined : LeftArrowSmall}
        leftIconLink={isScreenBig ? undefined : goBackLink}
      />
      <div className={cx('back-arrow-container')}>{desktopGoBackArrow}</div>

      <div className={cx('container')}>
        {isLoadingQuoteProducts && quoteProducts.length <= 0 ? (
          <RegularSpinner />
        ) : (
          <div className={cx('summary-container')}>
            <p>
              <strong>
                {GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.text1'))}
              </strong>
            </p>

            <div className={cx('pago-resumen-container')}>
              { isQuoteAccepted ? 
              <div>
                <div className={cx('quote-accepted-text-check')}>
                  <img src={GreenCheck} alt='Check' className={cx('quote-accepted-green-check')} />
                  {acceptedQuoteText}
                </div>
                <br/>
              </div> 
              : 
                '' 
              }
              <div className={cx(isQuoteAccepted ? 'quote-accepted-payment-summary' : '')}>
                <div>{formattedDetails}</div>
                <div className='border-t-2'>{totalLine}</div>
              </div>
            </div>

            <div className='w-full'>{addresBlock}</div>

            {buttonLine}
          </div>
        )}
      </div>
    </div>
  );
};
