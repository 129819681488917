import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './split-payment.module.css';
import { t } from 'i18next';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  useSelectedPatientValue,
  useSelectedQuoteValue,
  useSessionValue,
  useSetSelectedPatient,
  useSetSelectedQuote,
  useUpdateDeal,
  useUpdatePatient,
  useUpdateQuote,
  useViewport,
  useCloneQuote,
} from '../../hooks';
import {
  useGetDocumentInfo,
  useGetTemplateInfo,
  useShowErrorPopup,
  useSignUrl,
  useUserAddress,
} from '../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from '../../components/selects';
import { useForm } from '@tanstack/react-form';
import { validate } from '../../utils';
import { Input } from '../../components/Inputs/input';
import { Button, GoBackArrow, SignatureIframe, TopMenu } from '../../components';
import { testBicValidator } from '../../services';
import { DealPaymentsToSend, IPatient } from '../../hexagonal-architecture-frontend-base/src/domain/models/IPersona';
import { SignTemplate } from '../../ts';
import AddressBlock from '../../components/AddressBlock';
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import Loader from '../../components/Loaders/Loader';
import { QuotedItem } from 'src/ts/interfaces/quotes/quoted-item';
import { dealPaymentOptions, dealPaymentTypes } from '../../consts';

const cx = classNames.bind(styles);
const DOCUMENT_TEMPLATE_ID = process.env.REACT_APP_DOCUMENT_TEMPLATE_ID_SEPA;
const FEES = 38;

const formatIBAN = (value: string) => {
  return value
    .replace(/\s/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

const SplitPayment = () => {
  const { patientId, reportId } = useParams();
  const { viewportWidth } = useViewport();
  const { selectedPatient } = useSelectedPatientValue();
  const { userInfo } = useSessionValue();
  const { selectedQuote } = useSelectedQuoteValue();
  const navigate = useNavigate();
  const { getTemplateInfo, isLoading: isLoadingTemplateInfo } = useGetTemplateInfo();
  const { getSignUrl, signUrl, isLoading: isLoadingSignUrl } = useSignUrl();
  const { getDocumentInfo } = useGetDocumentInfo();
  const { showErrorPopup } = useShowErrorPopup();
  const { userAddress } = useUserAddress();
  const { updateQuote } = useUpdateQuote();
  const { updateDeal } = useUpdateDeal();
  const { cloneQuote } = useCloneQuote();

  const isLoadingTemplate = isLoadingTemplateInfo || isLoadingSignUrl;

  const dataToHoldRef = useRef({
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: signUrl?.docId,
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: signUrl?.reqId,
  });

  dataToHoldRef.current = {
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: signUrl?.docId,
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: signUrl?.reqId,
  };

  const [totalAmount, setTotalAmount] = useState<number>(selectedQuote?.Grand_Total ?? 0);
  const [numberOfPayments, setNumberOfPayments] = useState<string>('1');
  const [numberOfPaymentsOptionsState, setNumberOfPaymentsOptions] = useState<
    { value: string; content: JSX.Element }[]
  >([]);
  const [firstPayment, setFirstPayment] = useState<number>(0);
  const [totalAmountMonthly, setTotalAmountMonthly] = useState<number>(0);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [textToShow, setTextToShow] = useState<string>('establishConnection');
  const [getData, setGetData] = useState<boolean>(true);
  const [bicCode, setBicCode] = useState<string>('');
  const [showDocument, setShowDocument] = useState<boolean>(true);
  const [searchiingBic, setSearchiingBic] = useState<boolean>(true);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [financialCost, setFinancialCost] = useState<number>(0);
  const [quotesItems, setQuotesItems] = useState<QuotedItem[]>([]);
  const [iban, setIban] = useState<string>('');
  const { removeSelectedQuote } = useSetSelectedQuote();
  const { removeSelectedPatient } = useSetSelectedPatient();
  const { updatePatient } = useUpdatePatient();

  const textBase = 'pages.informeDetallado';
  const tutorName = userInfo?.name;
  let timer: any;

  const topMenuProperties: any = {
    title: `${GlobalService.uppercaseFirstLetter(t(`${textBase}.mainTitle`))}: ${
      selectedPatient.title
    }`,
    user: tutorName,
  };

  const isScreenBig = viewportWidth > 768;

  useEffect(() => {
    let options: { value: string; content: JSX.Element }[] = [];
    if ((totalAmount ?? 0) >= 300 && (totalAmount ?? 0) < 600) {
      options = [
        { value: '1', content: <span>1</span> },
        { value: '2', content: <span>2</span> },
        { value: '3', content: <span>3</span> },
      ];
    } else if ((totalAmount ?? 0) >= 600 && (totalAmount ?? 0) < 1000) {
      options = [
        { value: '1', content: <span>1</span> },
        { value: '2', content: <span>2</span> },
        { value: '3', content: <span>3</span> },
        { value: '4', content: <span>4</span> },
        { value: '5', content: <span>5</span> },
        { value: '6', content: <span>6</span> },
      ];
    } else if ((totalAmount ?? 0) >= 1000) {
      options = [
        { value: '1', content: <span>1</span> },
        { value: '2', content: <span>2</span> },
        { value: '3', content: <span>3</span> },
        { value: '4', content: <span>4</span> },
        { value: '5', content: <span>5</span> },
        { value: '6', content: <span>6</span> },
        { value: '7', content: <span>7</span> },
        { value: '8', content: <span>8</span> },
        { value: '9', content: <span>9</span> },
        { value: '10', content: <span>10</span> },
        { value: '11', content: <span>11</span> },
        { value: '12', content: <span>12</span> },
      ];
    }
    setNumberOfPaymentsOptions(options);
  }, [totalAmount]);

  useEffect(() => {
    setTotalAmount(selectedQuote?.Grand_Total ?? 0);
    const numberOfPaymentsCounter: number = parseInt(numberOfPayments);
    let monthlyPayment = 0;
    let financialCost = 0;
    let firstPayment = totalAmount;
    if (numberOfPaymentsCounter > 2) {
      monthlyPayment = parseFloat((totalAmount / numberOfPaymentsCounter).toFixed(2));
      financialCost = parseFloat((totalAmount * 0.07).toFixed(2));
      setFinancialCost(financialCost)
      firstPayment = FEES + financialCost + monthlyPayment;
    } else if (numberOfPaymentsCounter === 2) {
      monthlyPayment = parseFloat((totalAmount / numberOfPaymentsCounter).toFixed(2));
      firstPayment = monthlyPayment;
    }

    setFinancialCost(financialCost);
    setFirstPayment(firstPayment ? parseFloat(firstPayment.toFixed(2)) : 0);
    setTotalAmountMonthly(monthlyPayment ? monthlyPayment : 0);
  }, [numberOfPayments, firstPayment, selectedQuote]);

  const stopInterval = () => {
    clearInterval(timer);
    timer = null;
  };

  const getDocumentSignState = () => {
    if (dataToHoldRef.current.docId == null || dataToHoldRef.current.reqId == null) {
      return;
    }

    getDocumentInfo(
      { reqId: dataToHoldRef.current.reqId },
      {
        onSuccess: documentInfo => {
          if (documentInfo.requests.request_status !== 'completed' || !getData) return;

          setGetData(false);
          stopInterval();
          setTextToShow('savingDoc');
          handleSelectPayMethod();
        },
      },
    );
  };

  const fetchData = async (patient: IPatient, iban: string, bic: string) => {
    getTemplateInfo(
      {
        docId: DOCUMENT_TEMPLATE_ID,
      },
      {
        onError: () => {
          showErrorPopup('errors.signRequestFailed');
        },
        onSuccess: async templateData => {
          const dataToSend: { templates: SignTemplate } = {
            templates: {
              field_data: {
                field_text_data: {
                  ['Patient Name']: patient.title,
                  ['Care Home Address']: userAddress?.Direcci_n,
                  ['Postal Code']: userAddress?.Codigo_postal,
                  ['Country']: userAddress?.Pa_s,
                  ['Patient DNI']: selectedPatient.dni as string,
                  ['Swift BIC']: bic,
                  ['IBAN Number']: iban,
                  ['Number of Installments']: numberOfPayments.toString(),
                  ['Installment First Payment']: `${
                    parseInt(numberOfPayments) <= 2 ? '0' : firstPayment
                  }€`,
                  ['Monthly Ammount']: `${totalAmountMonthly.toString()}€`,
                  ['One Payment Total Ammount']: `${
                    parseInt(numberOfPayments) === 1 ? totalAmount : '0'
                  }€`,
                  ['Installment Total Ammount']: `${
                    parseInt(numberOfPayments) !== 1
                      ? parseInt(numberOfPayments) === 2
                        ? totalAmount
                        : totalAmount + financialCost + FEES
                      : '0'
                  }€`,
                  ['Tutor Name']: userInfo?.name ?? '',
                  ['Tutor DNI']: userInfo?.dni ?? '',
                },
                field_boolean_data: {
                  ['One Payment']: parseInt(numberOfPayments) > 1 ? false : true,
                  ['Installment Payment']: parseInt(numberOfPayments) > 1 ? true : false,
                },
              },
              actions: [
                {
                  ...templateData.templates.actions[0],
                  recipient_name: selectedPatient.title,
                  recipient_email: selectedPatient.email,
                  verify_recipient: false,
                  private_notes: '',
                  action_id: templateData.templates.actions[0].action_id,
                  is_embedded: true,
                },
              ],
              notes: '',
            },
          };
          delete dataToSend.templates.actions?.[0].fields;
          getSignUrl(
            {
              data: dataToSend,
              templateId: DOCUMENT_TEMPLATE_ID,
            },
            {
              onError: () => showErrorPopup('errors.signRequestFailed'),
              onSuccess: () => {
                setShowDocument(false);
                if (!timer) {
                  timer = setInterval(getDocumentSignState, 2500);
                }
              },
            },
          );
        },
      },
    );
  };

  const form = useForm({
    defaultValues: {
      accountName: '',
      address: '',
      iban: '',
      bic: '',
      country: '',
    },
    onSubmit: async values => {
      setShowDocument(false);
      setShowMessage(false);
      setShowSpinner(true);
      // handleSelectPayMethod();
      fetchData(selectedPatient, values.value.iban, bicCode);
      setIban(values.value.iban);
    },
  });

  const handleSelectPayMethod = () => {
    if (reportId) {
      updateQuote.receipt(reportId, numberOfPayments, totalAmount, totalAmount, {
        onSuccess: () => {
          setShowMessage(true);
          setTimeout(() => finishPayment(), 6000);
        },
      });
      cloneQuote.receipt(
        reportId,
        `Cloned - ${selectedQuote?.Subject}`,
        'Presupuesto aceptado',
        'Financiación',
        Number((FEES + financialCost).toFixed(2)),
        Number((FEES + financialCost).toFixed(2)),
        '1',
        [
          {
            productData: {
              id: '4560323000051230683',
              name: 'Gastos de Gestión',
              code: 'GG',
            },
            quantity: 1,
            listPrice: FEES,
          },
          {
            productData: {
              id: '4560323000278398147',
              name: 'Gastos de Financiación',
              code: 'Gf',
            },
            quantity: 1,
            listPrice: financialCost,
          },
        ]
      );
    }
  };

  const createDayOfMonthlyPayments = (numberOfPayments: number) => {
    const currentDate = new Date();
    const date = new Date();
    const currentDay = currentDate.getDate();
    let paymentDay = 0;
    let year = date.getFullYear();
    const datesList: string[] = [];
  
    for (let i = 0, c = 0; i < numberOfPayments; i++, c++) {
      if (numberOfPayments === 1) {   
        paymentDay = 13;
        if(currentDay >= 13) c++;
      } else if (numberOfPayments > 1) {
        paymentDay = 25;
        if (currentDay >= 25 && i === 0) c++;
      } else {
        if (c + currentDate.getMonth() === 12) {
          c = -currentDate.getMonth();
          year++;
        }
      }

      date.setMonth(currentDate.getMonth() + c);
      date.setDate(paymentDay);
      date.setFullYear(year);

      datesList.push(date.toISOString().substring(0, 10));
    }
  
    return datesList;
  }

  const createPaymentList = () => {
    const addedPayments: DealPaymentsToSend[] = [];
    const numberOfPaymentsCounter = parseInt(numberOfPayments);
    const datesList = createDayOfMonthlyPayments(parseInt(numberOfPayments));
    for (let i = 0; i < numberOfPaymentsCounter; i++) {
      const monthlyPayment = i === 0 ? firstPayment : totalAmountMonthly;
      addedPayments.push(
        {
          Cobrado: '',
          Fecha_Pago: datesList[i],
          M_todo_de_Pago: dealPaymentTypes.RECEIPT,
          Pago: monthlyPayment,
          Observaciones: dealPaymentOptions.OBSERVATIONS,
        } as unknown as DealPaymentsToSend,
      );
    }

    return addedPayments;
  };

  const finishPayment = () => {
    updatePatient({
      id: patientId as string,
      iban: iban,
      swiftBic: bicCode,
    });
    updateDeal.receipt(dataToHoldRef.current.dealId, dataToHoldRef.current.dealName, {
      App_Consumer_Payment_Managed: true,
      Pago_Inicial: true,
      Pago_Final: true,
      Tipo_de_Pago: dealPaymentTypes.RECEIPT,
      Signed_SEPA: true,
      Pagos: createPaymentList(),
    });
    removeSelectedPatient();
    removeSelectedQuote();
    navigate(`/personas`);
  };

  const isValidIBAN = (iban: string) => {
    const cleanIBAN = iban.replace(/\s+/g, '').toUpperCase();
    const rearranged = cleanIBAN.slice(4) + cleanIBAN.slice(0, 4);
    const numericIBAN = rearranged
      .split('')
      .map(char => {
        const code = char.charCodeAt(0);
        return code >= 65 && code <= 90 ? code - 55 : char;
      })
      .join('');
    const remainder = BigInt(numericIBAN) % 97n;
    return remainder === 1n;
  };

  const backLink = `/informeDetallado/${patientId}/${reportId}/paymentMethod`;

  return (
    <div className={cx('main-container')}>
      <TopMenu {...topMenuProperties} />

      {showDocument && !showMessage && (
        <div className={cx('body-container')}>
          {isScreenBig && (
            <div className={cx('title--container')}>
              <GoBackArrow to={backLink} />

              <h3 className={cx('title--text')}>
                {GlobalService.uppercaseFirstLetter(t(`pages.paymentMethod.split`))}
              </h3>
            </div>
          )}
          {selectedQuote && totalAmount !== 0 ? (
            <div className={cx('body-container-main')}>
              <div className={cx('form-container', 'bs-20')}>
                <p>
                  {`${GlobalService.uppercaseFirstLetter(
                    t('modal.splitPayment.totalAmount'),
                  )} ${totalAmount}€. ${GlobalService.uppercaseFirstLetter(
                    t('modal.splitPayment.choosePayment'),
                  )}`}
                  <br />
                  <p className={cx('added-fees')}>
                    {`${GlobalService.uppercaseFirstLetter(t('modal.splitPayment.addedFees'))}`}
                  </p>
                </p>
                <p>{t(`modal.splitPayment.selectNumberPayments`)}</p>
                <div>
                  {t(`modal.splitPayment.selectNumberPaymentsLable`)}
                  <Select
                    label={numberOfPayments}
                    setValue={setNumberOfPayments}
                    options={numberOfPaymentsOptionsState}
                  />
                </div>

                <div className={cx('details-container')}>
                  <p>
                    <span className={cx('title')}>
                      {GlobalService.uppercaseFirstLetter(
                        t('modal.splitPayment.totalAmountFirstPayment'),
                      )}
                    </span>{' '}
                    {firstPayment}€
                  </p>
                  {parseInt(numberOfPayments) > 1 ? (
                    <p>
                      <span className={cx('title')}>
                        {GlobalService.uppercaseFirstLetter(
                          t('modal.splitPayment.totalAmountMonthly'),
                        )}{' '}
                      </span>
                      {totalAmountMonthly}€
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={cx('form-container', 'bs-20')}>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    form.handleSubmit();
                  }}
                  className={cx('flex flex-col gap-2 mx-2')}
                >
                  <form.Field
                    name={'accountName'}
                    validators={{
                      onChange: value => (validate(value.value, 'name') ? undefined : 'error.'),
                    }}
                    // eslint-disable-next-line react/no-children-prop
                    children={field => (
                      <Input
                        id={field.name}
                        label={GlobalService.uppercaseFirstLetter(
                          t('modal.splitPayment.accountHolder'),
                        )}
                        placeholder=''
                        type={'text'}
                        value={field.state.value}
                        onChange={field.handleChange}
                        onBlur={field.handleBlur}
                        errorMessage={field.state.meta.errors.join(', ')}
                      />
                    )}
                  />
                  <form.Field
                    name={'iban'}
                    validators={{
                      onChange: value => {
                        return validate(value.value, 'iban') && isValidIBAN(value.value)
                          ? undefined
                          : GlobalService.uppercaseFirstLetter(t('modal.splitPayment.errorIban'));
                      },
                      onChangeAsync: async val => {
                        const ibanValue = val.value.replace(/\s+/g, '');
                        if (validate(ibanValue, 'iban')) {
                          try {
                            const searchBic = await testBicValidator(ibanValue.slice(4, 8));
                            if (searchBic === 'DontExistInDatabase' && searchiingBic) {
                              setBicCode('');
                              setSearchiingBic(true);
                              return GlobalService.uppercaseFirstLetter(
                                t('modal.splitPayment.bicDontExist'),
                              );
                            } else {
                              setBicCode(searchBic);
                              setSearchiingBic(false);
                            }
                          } catch (error) {
                            console.error('Error fetching BIC', error);
                            return GlobalService.uppercaseFirstLetter(
                              t('modal.splitPayment.errorFetchingBic'),
                            );
                          }
                        } else {
                          return GlobalService.uppercaseFirstLetter(
                            t('modal.splitPayment.errorIban'),
                          );
                        }
                      },
                      onChangeAsyncDebounceMs: 1000,
                      onChangeListenTo: ['iban', 'bic'],
                    }}
                    // eslint-disable-next-line react/no-children-prop
                    children={field => (
                      <Input
                        id={field.name}
                        label={GlobalService.uppercaseFirstLetter(t('modal.splitPayment.iban'))}
                        placeholder=''
                        type={'text'}
                        value={formatIBAN(field.state.value.toLocaleUpperCase())}
                        onChange={field.handleChange}
                        onBlur={field.handleBlur}
                        errorMessage={field.state.meta.errors.join(', ')}
                      />
                    )}
                  />
                  <form.Field
                    name={'bic'}
                    validators={{
                      onChange: value => {
                        setSearchiingBic(value.value.length === 0);
                        return undefined;
                      },
                      onChangeListenTo: ['iban', 'bic'],
                    }}
                    // eslint-disable-next-line react/no-children-prop
                    children={field => (
                      <Input
                        id={field.name}
                        label={t('modal.splitPayment.bic')}
                        placeholder=''
                        type={'text'}
                        value={field.state.value || bicCode}
                        onChange={field.handleChange}
                        onBlur={field.handleBlur}
                        errorMessage={field.state.meta.errors.join(', ')}
                      />
                    )}
                  />
                  <form.Field
                    name={'address'}
                    validators={{
                      onChange: ({ value }) =>
                        validate(value, 'Direcci_n') ? undefined : 'error.',
                    }}
                    // eslint-disable-next-line react/no-children-prop
                    children={field => (
                      <AddressBlock isScreenBig={isScreenBig} title={'Direccion'} />
                    )}
                  />
                  <form.Subscribe
                    // @ts-ignore
                    selector={state => [state.canSubmit, state.isSubmitting]}
                    // @ts-ignore
                    // eslint-disable-next-line react/no-children-prop
                    children={([canSubmit, isSubmitting]) => (
                      <Button
                        styleType='primary'
                        disabled={
                          canSubmit &&
                          !isLoadingTemplate &&
                          numberOfPayments === t('modal.splitPayment.numberPayments')
                        }
                        fullWidth={true}
                        type='submit'
                        isLoading={isSubmitting || isLoadingTemplate}
                      >
                        {isSubmitting ? (
                          <Loader size='23px' border='3px' />
                        ) : (
                          GlobalService.uppercaseFirstLetter(t('modal.splitPayment.continue'))
                        )}
                      </Button>
                    )}
                  />
                </form>
              </div>
            </div>
          ) : (
            <RegularSpinner />
          )}
        </div>
      )}
      {!showDocument && !showMessage && (
        <div className='w-full h-full flex flex-col gap-8 items-center justify-around'>
          {showSpinner ? <RegularSpinner /> : null}
          {signUrl?.url ? (
            <SignatureIframe url={signUrl.url} onLoad={() => setShowSpinner(false) } />
          ) : (
            <p className='text-center max-w-xl font-bold'>
              {GlobalService.uppercaseFirstLetter(t(`pages.signature.${textToShow}`))}
            </p>
          )}
        </div>
      )}
      {showMessage && (
        <div className={cx('message-container')}>
          <div className={cx('message-container')}>
            <p className={cx('message-container-text')}>
              {GlobalService.uppercaseFirstLetter(
                t('modal.transferencia.confirmTransferMethodMessage'),
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SplitPayment;
