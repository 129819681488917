import { MutateOptions } from '@tanstack/query-core';
import { useAtomValue } from 'jotai';
import { QUOTE_PAYMENT_TYPES } from '../../../consts';
import { updateQuoteAtom } from '../../../store/global/atoms';
import { ApiResponse, updateQuoteParams } from '../../../ts';
import { QuotedItem } from 'src/ts/interfaces/quotes/quoted-item';
import { QUOTE_STAGE } from '../../../consts';

type Options = MutateOptions<ApiResponse<any>, Error, updateQuoteParams>;

export const useUpdateQuote = () => {
  const updateQuoteBase = useAtomValue(updateQuoteAtom);

  const updateQuote = {
    transfer: (quoteId: string, quotedItems: QuotedItem[], acceptedAmount: number, options?: Options) =>
      updateQuoteBase.mutate(
        { quoteId, paymentType: QUOTE_PAYMENT_TYPES.TRANSFER, quotedItems, acceptedAmount },
        options,
      ),
    receipt: (quoteId: string, numberOfPayments: string, acceptedAmount: number, payment1: number, options?: Options,) => 
      updateQuoteBase.mutate(
        { quoteId, paymentType: QUOTE_PAYMENT_TYPES.RECEIPT, numberOfPayments, acceptedAmount, payment1 },
        options,
      ),
    card: (quoteId: string, quotedItems: QuotedItem[], numberOfPayments: string, acceptedAmount: number, options?: Options) =>
      updateQuoteBase.mutate(
        { quoteId, paymentType: QUOTE_PAYMENT_TYPES.CARD, quotedItems, numberOfPayments, acceptedAmount },
        options,
      ),
    acceptedInAppConsumer: (quoteId: string, acceptedInAppConsumer: boolean ) => {
      updateQuoteBase.mutate({ quoteId, acceptedInAppConsumer, quoteStage: QUOTE_STAGE.ACCEPTED });
    },
  };

  return {
    updateQuoteResult: updateQuoteBase.data,
    updateQuote,
    isLoading: updateQuoteBase.isPending,
    error: updateQuoteBase.error,
  };
};
