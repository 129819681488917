import styles from './tutor-buttons.module.css';
import classNames from 'classnames/bind';
import { FC } from 'react';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Buttons';

const cx = classNames.bind(styles);

interface TutorButtons {
  isEditing: boolean;
  isSaveTutorButtonDisabled: boolean;
  handleClickEdit: () => void;
  handleCancelEdit: () => void;
  handleClickUpdateUser: () => Promise<void>;
}

export const TutorButtons: FC<TutorButtons> = ({
  isEditing,
  isSaveTutorButtonDisabled,
  handleClickEdit,
  handleCancelEdit,
  handleClickUpdateUser,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx('buttons-container')}>
      {!isEditing ? (
        <Button onClick={handleClickEdit} fullWidth={true}>
          {GlobalService.uppercaseFirstLetter(t('edit'))}
        </Button>
      ) : (
        <>
          <Button
            onClick={handleClickUpdateUser}
            disabled={isSaveTutorButtonDisabled}
            fullWidth={true}
          >
            {GlobalService.uppercaseFirstLetter(t('saveChanges'))}
          </Button>

          <Button styleType='secondary' onClick={handleCancelEdit} fullWidth={true}>
            {GlobalService.uppercaseFirstLetter(t('cancel'))}
          </Button>
        </>
      )}
    </div>
  );
};
