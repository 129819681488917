import { ApiResponse, UpdateDealParams } from 'src/ts';
import axios from 'axios';

export const updateDealService = async ({ dealId, dealName, dealOptions }: UpdateDealParams ): Promise<ApiResponse<any>> => {
    let result: ApiResponse<any>;

    const data = {
      dealId,
      dealName,
      dealOptions,
    };
  
    try {
      const response = await axios.patch<ApiResponse<any>>('/patients/update-deal', data);
      result = response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  
    if (!result.isOk) {
      throw new Error(result.message);
    }
  
    return result;
};
