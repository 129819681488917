import { PopupOptionsSetter } from 'src/ts';
import { useSetPopup } from '../use-popup';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { ConfirmPaymentMethodPopup } from '../../../components';
import { IPatientDeal } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IPersona';

export const useConfirmPaymentMethodPopup = () => {
  const { t } = useTranslation();
  const { openPopup } = useSetPopup();

  const showConfirmPaymentMethodPopup = (onSuccess: () => void, dealDetails: Omit<IPatientDeal, "Contact_Name" | "patient">, paymentTotalAmount: number) => {
    const confirmPaymentMethodPopupOptions: PopupOptionsSetter = {
      title: GlobalService.uppercaseJustFirstLetter(
        t('modal.transferencia.confirmPaymentMethodModalTitle'),
      ),
      children: <ConfirmPaymentMethodPopup onSuccessCallback={onSuccess} dealDetails={dealDetails} paymentTotalAmount={paymentTotalAmount}/>,
    };

    openPopup(confirmPaymentMethodPopupOptions);
  };

  return {
    showConfirmPaymentMethodPopup,
  };
};
