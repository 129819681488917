import { useForm } from '@tanstack/react-form';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../../../components';
import { useSelectedPatient, useShowErrorPopup, useUpdatePatient } from '../../../../hooks';
import { validate } from '../../../../utils';
import { FC, useRef } from 'react';

interface EditFormProps {
  cancelEdit: () => void;
}

export const EditForm: FC<EditFormProps> = ({ cancelEdit }) => {
  const { t } = useTranslation();
  const { selectedPatient } = useSelectedPatient();
  const { updatePatientAsync } = useUpdatePatient();
  const { showErrorPopup } = useShowErrorPopup();

  const initialDni = useRef(selectedPatient.dni);

  const { handleSubmit, Field, Subscribe } = useForm({
    defaultValues: {
      dni: initialDni.current || '',
    },
    onSubmit: async values => {
      try {
        await updatePatientAsync({ id: selectedPatient.id, dni: values.value.dni });
      } catch (error) {
        console.error(error);
        showErrorPopup(t('errors.unexpectedError'));
      }

      cancelEdit();
    },
  });

  return (
    <form
      className={'flex flex-col  w-full gap-4'}
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
    >
      <Field
        name='dni'
        validators={{
          onChange: ({ value }) => {
            const isValid = validate(value, 'dni');
            return isValid ? undefined : 'errors.dniInvalid';
          },
        }}
        // eslint-disable-next-line react/no-children-prop
        children={field => (
          <Input
            id={field.name}
            label='dni'
            placeholder=''
            type='text'
            value={field.state.value}
            onChange={field.handleChange}
            errorMessage={field.state.meta.errors.join(', ')}
          />
        )}
      />

      <Subscribe
        // @ts-ignore
        selector={state => [state.canSubmit, state.isSubmitting, state.values.dni]}
        // @ts-ignore
        // eslint-disable-next-line react/no-children-prop
        children={([canSubmit, isSubmitting, dni]) => {
          return (
            <Button
              type='submit'
              className='mt-1'
              disabled={!canSubmit || dni === initialDni.current}
              // @ts-ignore
              isLoading={isSubmitting}
              fullWidth={true}
            >
              {t('saveChanges')}
            </Button>
          );
        }}
      />
    </form>
  );
};
