import { http } from '../plugins/http/http';
import { UserRepository } from '../../domain/repositories/user.repository';
import { User, NewUser } from '../../domain/models/User';
import { UserDTO } from '../dto/userDTO';
import { ITutor, ITutorData, IUserData, IVerifyMail } from '../../domain/models/ITutor';

const tutors: ITutor[] = [
  {
    id: 1,
    name: 'CARMEN MARTINEZ',
    dni: 'X019238091N',
    email: 'cmartinez1234@gmail.com'
  },
  {
    id: 2,
    name: 'JUANJO FONSECA',
    dni: 'X1233445H',
    email: 'juanjofon1234@gmail.com'
  }
];

const error: ITutor = {
  id: 0,
  name: 'Error',
  dni: 'Error',
  email: 'Error'
};

export const userRepository = (): UserRepository => ({
  createUser: async (user: NewUser) => {
    const newUser = await http.post<UserDTO>('', { user });
    const returnUser: User = {
      id: newUser.id,
      username: newUser.username,
      role: newUser.role
    };
    return returnUser;
  },
  deleteUser: async (id?: string) => {
    const deletedUser = await http.delete<UserDTO>(`${id}`);
    const returnUser: User = {
      id: deletedUser.id,
      username: deletedUser.username,
      role: deletedUser.role
    };
    return returnUser;
  },
  getActiveUser: async (): Promise<IUserData> => {
    const returnValue: IUserData = {
      errorPrisma: '',
      Prisma: { dni: '', email: '', id: 0, name: '', patientsAccess: false, phone: '', stripeId: '' }
    };
    await http
      .get('/users/me')
      .then((response: any) => {
        Object.assign(returnValue.Prisma, response);
      })
      .catch(() => {
        returnValue.errorPrisma = 'userDataInvalid';
      });
    return returnValue;
  },
  getTutor: async () => {
    const tutorId = parseInt(window.localStorage.getItem('tutorId') as string);
    const selectedUser = tutors.find(item => item.id === tutorId);
    if (selectedUser) {
      return selectedUser;
    } else {
      return error;
    }
  },
  getUser: async (id?: number) => {
    const selectedUser = tutors.find(item => item.id === id);
    if (selectedUser) {
      return selectedUser;
    } else {
      return error;
    }
  },
  updateUser: async (user: ITutorData): Promise<ITutorData | string> => {
    const clonedUser: ITutorData = { ...user };
    delete clonedUser.id;
    const returnValue: ITutorData | string = await http
      .put('/users/me', clonedUser)
      .then(response => response as ITutorData)
      .catch(() => {
        return 'updateInvalid';
      });
    return returnValue;
  },
  verifyEmail: async (data: IVerifyMail) => {
    let returnValue;
    await http
      .post(`/users/${data.userId}/verify`, { code: data.code })
      .then(response => {
        returnValue = response;
      })
      .catch(() => {
        returnValue = 'verifyInvalid';
      });
    return returnValue;
  }
});
