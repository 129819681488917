import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Input } from '../../../components/Inputs/input/Input';
import { ButtonBase } from '../../../components/Buttons/button-base';

// Services
import { authServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/auth.services';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { validate } from '../../../hexagonal-architecture-frontend-base/src/domain/services/user.service';
import { useTranslation } from 'react-i18next';

import styles from './forgot-password.module.css';

// Hooks
import { useViewport } from '../../../hooks/use-viewport';
import { LoginSignupLayout, MobileVerificationForm } from '../../../components';
import { useResetPassword } from '../../../hooks/user-session/reset-password/use-reset-password';
import { useMobileVerificationIntervals, useVerifyMobile } from '../../../hooks';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { error: resetPasswordError, clear: clearReset } = useResetPassword();
  const { verifyCode } = useVerifyMobile();

  /**********
   * States *
   **********/
  const [cancelButtonText, setCancelButtonText] = useState<string>('cancel');
  const [ email, setEmail ] = useState<string>('');
  const [ phone, setPhone ] = useState<string>('');
  const [ errorMessage, setErrorMessage ] = useState<string>('');
  const [ explanationText, setExplanationText ] = useState<string>('recoverPasswordTextPhone');
  const [ okButtonText, setOkButtonText ] = useState<string>('sendPhone');
  const [ showForm, setShowForm ] = useState<boolean>(true);
  useMobileVerificationIntervals();
  const { viewportWidth } = useViewport();
  const [ selectedType, setSelectedType ] = useState<string>('phone');
  const [ showCodeForm, setShowCodeForm ] = useState<boolean>(false);

  /************
   * Handlers *
   ************/
  const handleCancel = () => {
    if (cancelButtonText === 'cancel') {
      navigate('/login');
    } else {
      setCancelButtonText('cancel');
      setOkButtonText(`send${GlobalService.uppercaseFirstLetter(selectedType)}`);
      setExplanationText(selectedType === 'phone' ? `recoverPasswordText${GlobalService.uppercaseFirstLetter('phone')}` : `recoverPasswordText${GlobalService.uppercaseFirstLetter('email')}`);
      setShowForm(true);
    }
  };

  const handleChangeEmail = (e: string) => {
    const isValid = validate(e, 'email');
    if (!isValid) {
      setErrorMessage(GlobalService.uppercaseFirstLetter(t('errors.emailInvalid')));
    } else {
      setErrorMessage('');
    }
    setEmail(e);
  };

  const handleChangePhone = (e: string) => {
    const isValid = validate(e, 'phone');
    if (!isValid) {
      setErrorMessage(GlobalService.uppercaseFirstLetter(t('errors.phoneInvalid')));
    } else {
      setErrorMessage('');
    }
    setPhone(e);
  };

  const handleSubmit = async () => {
    if (okButtonText === 'sendPhone') {
      setCancelButtonText('sendAgain');
      setOkButtonText('login');
      setExplanationText('okTextSms');
      setShowForm(false);
      setShowCodeForm(true);
    } else if (okButtonText === 'sendEmail') {
      authServices()
        .forgotPassword({ type: 'email', selectedType: email })
        .then(() => {
          setCancelButtonText('sendAgain');
          setOkButtonText('login');
          setExplanationText('okTextEmail');
          setShowForm(false);
        });
    } else {
      navigate('/login');
    }
  };

  /**************
   * JSX Values *
   **************/
  const buttonDisabled = !((email || phone) && !errorMessage);
  const isScreenBig = viewportWidth > 768;
  const isScreenBigHeight = viewportWidth > 600;
  const cancelButton = (
    <div className='mt-4'>
      <ButtonBase
        text={GlobalService.uppercaseFirstLetter(t(`pages.forgotPassword.${cancelButtonText}`))}
        background='linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'
        color={'white'}
        borderRadius={'50px'}
        onClick={handleCancel}
        buttonWidth='100%'
      />
    </div>
  );

  const userInputs = showForm ? (
    <>
    { selectedType === 'phone' ? 
      <div>
        <div className='mt-1'>
          <Input
            id='phone'
            label={''}
            name='phone'
            placeholder={GlobalService.uppercaseFirstLetter(t('pages.forgotPassword.phone'))}
            type='text'
            value={phone}
            onChange={handleChangePhone}
          />
        </div>
      </div>
      :
      <div className='mt-1 '>
        <Input
          id='email'
          label={''}
          name='email'
          placeholder={GlobalService.uppercaseFirstLetter(t('pages.forgotPassword.email'))}
          type='text'
          value={email}
          onChange={handleChangeEmail}
        />
      </div>
  }
  </>
  ) : null;

  const switchForm = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;
    setSelectedType(id);
    setExplanationText(`recoverPasswordText${GlobalService.uppercaseFirstLetter(id)}`);
    setOkButtonText(`send${GlobalService.uppercaseFirstLetter(id)}`);
  };

  const backToForgotPasswordForm = () => {
    setShowCodeForm(false);
    console.log('SELECTED TYPE ', selectedType);
    setExplanationText(selectedType === 'phone' ? `recoverPasswordText${GlobalService.uppercaseFirstLetter('phone')}` : `recoverPasswordText${GlobalService.uppercaseFirstLetter('email')}`);
    setShowForm(true);
  }

  const handleCodeVerified = async (code: string) => {
    try {
      console.log('code ', code);
      verifyCode(
        { 
          mobile: '34' + phone,
          code
        },
        {
          onSuccess: async (response) => {
            if (response.data.data.valid) {
              const token = await authServices().forgotPassword({ selectedType: phone, type: 'phone' });
              if (token !== null) {
                navigate(`/resetPassword/${token}`, { state: { phone: phone }});
              }
            }
          },
          onError: (error: any) => {
            console.error('error', error);
          },
        },
      );
    } catch (error: any) {
      console.error('error', error);
    }
  };

  /*******
   * JSX *
   *******/
  return (
     <LoginSignupLayout type={'forgotPassword'}>
    <div>
        <div className={cx('forgot-password-form-container')}>
         { !showCodeForm ? 
         <>
            <div >
              <div className={cx('form-explanation')}>
                <p className={cx('recover-password-message')}>
                  {t(`pages.forgotPassword.${explanationText}`)}
                </p>
                { showForm ? 
                  <>
                    <p className={cx('recover-password-message')}>
                      {t(`pages.forgotPassword.selectPhoneOrEmail`)}
                    </p>
                    <div className={cx('forgot-password-container-form-type-selector')}>
                      <div id='phone' onClick={switchForm} className={selectedType === 'phone' ? cx('forgot-password-form-type-selector-selected') : cx('forgot-password-form-type-selector')}>{t('pages.forgotPassword.phone')}</div> 
                      &nbsp;|&nbsp;
                      <div id='email' onClick={switchForm} className={selectedType === 'phone' ? cx('forgot-password-form-type-selector') : cx('forgot-password-form-type-selector-selected')}>{t('pages.forgotPassword.email')}</div>
                    </div>
                  </>
                  :
                  null
                } 
                {userInputs}
                {errorMessage ? <p className={cx('error-message')}>{errorMessage}</p> : null}
                <div className='mt-14'>
                  <ButtonBase
                    text={GlobalService.uppercaseFirstLetter(t(`pages.forgotPassword.${okButtonText}`))}
                    background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
                    color={'white'}
                    borderRadius={'50px'}
                    onClick={handleSubmit}
                    buttonWidth='100%'
                    disabled={buttonDisabled}
                  />
                </div>
                {cancelButton}
              </div>
            </div>
          </>
        :
        <>
          <MobileVerificationForm
              mobile={phone}
              goBack={backToForgotPasswordForm}
              handleSubmit={handleCodeVerified}
              clearSubmitError={clearReset}
              submitError={resetPasswordError?.message}
          />
          
        </>
        }
      </div>
    </div>
    </LoginSignupLayout>
  );
};

export default ForgotPassword;
