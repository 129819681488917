// CSS
import '../../styles/Personas/Personas.css';
import styles from './informes.module.css';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
// Components
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import Title from '../../components/Title';
import { QuoteCard } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Images
import LeftArrow from '../../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { usePatients, useSelectedPatient, useSelectedQuote, useShowErrorPopup } from '../../hooks';
import { TopMenu } from '../../components';
import { PatientQuote } from 'src/ts';

const cx = classNames.bind(styles);

const Informes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const {
    patients,
    quotesError: reportsError,
    isLoading: isPatientsLoading,
    isReportsLoading,
  } = usePatients();
  const { viewportWidth } = useViewport();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { showErrorPopup } = useShowErrorPopup();
  const quotes = selectedPatient.reportsDetails ?? [];

  useSelectedQuote();
  /**********
   * States *
   **********/

  const isLoading =
    (isPatientsLoading && patients.length === 0) || (isReportsLoading && quotes.length === 0);

  /*********
   * Hooks *
   *********/
  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patients]);

  useEffect(() => {
    // TODO - Make it possible to fetch the reports for a single patient
    // getAllPatients();
  }, [selectedPatient]);

  /************
   * Handlers *
   ************/
  useEffect(() => {
    if (reportsError != null) {
      showErrorPopup(reportsError.message);
    }
  }, [patients, reportsError]);

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.reports.mainTitle'))}:`;
  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;

  const formattedReports = quotes.map((report: PatientQuote, index: number) => {
    return <QuoteCard key={index} quote={report} />;
  });

  // Responsive values
  const responsivePageTitle = GlobalService.uppercaseFirstLetter(
    t('pages.reports.responsiveMainTitle'),
  );

  /*******
   * JSX *
   *******/
  return (
    <div className={cx('main-container')}>
      {isScreenBig ? (
        <>
          <TopMenu title={pageTitle} userName={selectedPatient.title} />

          <div className={cx('body-container')}>
            <div className='flex text-left mb-6'>
              <img
                onClick={() => {
                  navigate(`/datosDocumentos/${patientId}`);
                }}
                src={LeftArrow}
                alt='Back'
                className='Notificaciones-Arrow cursor-pointer'
              />

              <Title
                text={`${GlobalService.uppercaseFirstLetter(t('pages.reports.title'))}:`}
                color='black'
                size={'24px'}
                margin={'0px 0px 0px 30px'}
              />
            </div>

            <div className={cx('reports-container')}>
              {isLoading ? <RegularSpinner /> : quotes.length ? formattedReports : noData}
            </div>
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title={responsivePageTitle}
            leftIcon={LeftArrowSmall}
            leftIconLink={`/datosDocumentos/${patientId}`}
          />

          <div className={cx('body-container', 'flex flex-col gap-y-2')}>
            {isLoading ? (
              <div className='mt-36 mx-auto'>
                <RegularSpinner />
              </div>
            ) : quotes.length ? (
              <div className={cx('reports-scroll')}>
                <div className={cx('reports-container')}>{formattedReports}</div>
              </div>
            ) : (
              noData
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Informes;
