import axios from 'axios';
import { ApiResponse } from 'src/ts';
import { ResetPasswordParams } from 'src/ts/interfaces/user-session/reset-password';

export const resetPasswordService = async ({ phone, password }: ResetPasswordParams ) => {
  try {

    const axiosResponse = await axios.post<ApiResponse>('/password/reset', {
      phone,
      password
    });

    const response: ApiResponse = axiosResponse.data;

    return response;
  } catch (error: any) {
    throw new Error('User-Session - ResetPassword.Service: ' + error.message);
  }

  return;
}