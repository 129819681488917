import { useAtomValue } from 'jotai';
import { loginAtom, loginWithTokenAtom } from '../../store/global/atoms';
import { l } from '@tanstack/query-core/build/legacy/hydration-yBB_smkL';

export const useLogin = () => {
  const login = useAtomValue(loginAtom);
  const loginWithToken = useAtomValue(loginWithTokenAtom);

  return {
    loginData: login.data,
    login: login.mutate,
    loginAsync: login.mutateAsync,
    isLoading: login.isPending,
    error: login.error,
    loginWithTokenData: loginWithToken.data,
    loginWithTokenError: loginWithToken.error,
    loginWithTokenIsLoading: loginWithToken.isPending,
    loginWithToken: loginWithToken.mutate,
    loginWithTokenAsync: loginWithToken.mutateAsync,
  };
};
