import { useSetAtom } from 'jotai';
import { userInfoAtom, authAtom } from '../../../store/global/atoms';
import type { Auth, UserInfo } from '../../../ts';
import { RESET } from 'jotai/vanilla/utils';
import { ActiveUserService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/activeUser.service';

const EMPTY_USER_NAME = 'EMPTY_NAME';

export const useSetSession = () => {
  const setAuth = useSetAtom(authAtom);
  const setUserInfo = useSetAtom(userInfoAtom);

  const setUserInfoSafe = (user: UserInfo) => {
    const sanitizedUser: UserInfo = {
      ...user,
      name: user.name === EMPTY_USER_NAME ? null : user.name,
    };
    setUserInfo(sanitizedUser);
  };

  const setSession = (auth: Auth, user: UserInfo) => {
    setAuth(auth);
    setUserInfoSafe(user);
  };

  const removeSession = () => {
    const itemsToRemove: string[] = ['backRoute', 'tutorId', 'tutorName', 'token'];
    itemsToRemove.forEach(item => window.localStorage.removeItem(item));
    setAuth(RESET);
    setUserInfo(RESET);
  };

  const refreshUser = async () => {
    const response = await ActiveUserService.initData(true);
    setUserInfo(response.Prisma);
  };

  return { setAuth, setUserInfo: setUserInfoSafe, setSession, removeSession, refreshUser };
};
