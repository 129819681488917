import { ChangeEvent, FC, useState } from 'react';
import { GlobalService } from '../../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../Buttons/button/button';
import {
  useSelectedPatient,
  useSetPatients,
  useSetPopup,
  useUploadPatomedi,
} from '../../../../hooks';
import Loader from '../../../../components/Loaders/Loader';

interface UploadPatomediPopup {
  newFileWillReplaceOldOneReminder?: boolean;
}

export const UploadPatomediPopup: FC<UploadPatomediPopup> = ({
  newFileWillReplaceOldOneReminder,
}) => {
  const { t } = useTranslation();
  const { uploadPatomedi } = useUploadPatomedi();
  const { selectedPatient } = useSelectedPatient();
  const { getAllPatients } = useSetPatients();
  const { openNextPopup, setForceKeepOpen } = useSetPopup();
  const [file, setFile] = useState<File | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const verifyFile = (file: File | null) => {
    if (file == null) {
      setErrorText('errors.noFileSelected');
      return false;
    }

    // Agafo l'extensió de l'arxiu
    // const fileExtension = file.name.substring(file.name.indexOf('.'));
    const fileExtension = file.name.split('.').pop();

    // Comprovo que sigui pdf o word document
    // if (fileExtension !== '.pdf' && fileExtension !== '.doc' && fileExtension !== '.docx') {
    if (fileExtension !== 'pdf' && fileExtension !== 'doc' && fileExtension !== 'docx') {
      setErrorText('errors.invalidFileExtension');
      return false;
    }

    // Comprovo la mida màxima: 20Mb
    if (file.size > 120971520) {
      setErrorText('errors.overSizedFile');
      return false;
    }

    return file;
  };

  const handleSetFile = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorText(null);
    const fileIsValid = verifyFile(event.target.files?.[0] ?? null);
    fileIsValid && setFile(fileIsValid);
  };

  const handleUploadFile = () => {
    setErrorText(null);
    const validFile = verifyFile(file);
    if (!validFile) return;

    setIsLoading(true);

    const data = new FormData();
    data.append('file', validFile);

    setForceKeepOpen.enable();

    uploadPatomedi(
      {
        file: data,
        dealId: selectedPatient.dealDetails.id,
        contactId: selectedPatient.id,
        fileId: {
          deal: selectedPatient.dealDetails.Archivo_Patomedi?.[0].id ?? '',
        },
      },
      {
        onSuccess: () => {
          getAllPatients();
          openNextPopup();
        },
        onError: err => {
          console.error(err);
          setErrorText('errors.invalidPatoMediUpdate');
        },
        onSettled: () => {
          setIsLoading(false);
          setForceKeepOpen.disable();
        },
      },
    );
  };

  return (
    <div className='flex flex-col justify-center items-left gap-6'>
      <p className='text-left lg:w-[575px]'>
        {GlobalService.uppercaseAfterPointFirstsLetters(t('pages.documentos.selectPatomediFile'))}
      </p>

      {newFileWillReplaceOldOneReminder ? (
        <p className='text-left lg:w-[575px]'>
          {GlobalService.uppercaseAfterPointFirstsLetters(
            t('pages.documentos.newFileWillReplaceOldFileReminder'),
          )}
        </p>
      ) : null}

      <div
        className='appearance-none flex items-center w-full border border-gray-200 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
        style={{ borderRadius: '25px' }}
      >
        <input type='file' onChange={e => handleSetFile(e)} />
      </div>

      {errorText && (
        <p className='text-red-500'>
          {GlobalService.uppercaseAfterPointFirstsLetters(t(errorText))}
        </p>
      )}

      <div className='flex justify-center w-full'>
        <Button
          className='w-full'
          styleType='primary'
          onClick={() => handleUploadFile()}
          // disabled={file == null}
        >
          {isLoading ? (
            <Loader size='26px' border='5px' />
          ) : (
            GlobalService.uppercaseFirstLetter(t('pages.documentos.upload'))
          )}
        </Button>
      </div>
    </div>
  );
};
