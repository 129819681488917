import { useAtomValue } from 'jotai';
import { MutateOptions } from '@tanstack/query-core';
import { cloneQuoteAtom } from '../../../store/global/atoms';
import { ApiResponse } from '../../../ts';
import { QuotedItem } from 'src/ts/interfaces/quotes/quoted-item';
import { CloneQuoteParams } from 'src/ts/interfaces/quotes/clone-quote-params';

type Options = MutateOptions<ApiResponse<any>, Error, CloneQuoteParams>;

export const useCloneQuote = () => {
  const cloneQuoteBase = useAtomValue(cloneQuoteAtom);
  
  const cloneQuote = {
    transfer: (
      quoteId: string, 
      subject: string, 
      stage: string, 
      reportType: string, 
      acceptedAmount: number, 
      payment1: number, 
      numberOfPayments: string, 
      quotedItems: QuotedItem[], 
      options?: Options) =>
      cloneQuoteBase.mutate({ quoteId, subject, stage, reportType, acceptedAmount, payment1, numberOfPayments, quotedItems }, options),

    receipt: (
      quoteId: string, 
      subject: string, 
      stage: string, 
      reportType: string, 
      acceptedAmount: number, 
      payment1: number, 
      numberOfPayments: string, 
      quotedItems: QuotedItem[], 
      options?: Options) =>
      cloneQuoteBase.mutate({ quoteId, subject, stage, reportType, acceptedAmount, payment1, numberOfPayments, quotedItems }, options),

    card: (
      quoteId: string, 
      subject: string, 
      stage: string, 
      reportType: string, 
      acceptedAmount: number, 
      payment1: number, 
      numberOfPayments: string, 
      quotedItems: QuotedItem[], 
      options?: Options) =>
      cloneQuoteBase.mutate({ quoteId, subject, stage, reportType, acceptedAmount, payment1, numberOfPayments, quotedItems }, options),
  };

  return {
    cloneQuoteResult: cloneQuoteBase.data,
    cloneQuote,
    error: cloneQuoteBase.error,
    isLoading: cloneQuoteBase.isPending,
  };
};
