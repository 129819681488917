import styles from './sign-form.module.css';
import classNames from 'classnames/bind';
import { useForm } from '@tanstack/react-form';
import { useTranslation } from 'react-i18next';
import { validate } from '../../../utils';
import { SignInput } from '../sign-input';
import { Button } from '../../../components/Buttons';
import Loader from '../../../components/Loaders/Loader';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { FC, useRef, useState } from 'react';
import type { SignTemplate, UserSignData } from 'src/ts/interfaces';
import {
  useGetDocumentInfo,
  useGetTemplateInfo,
  useSelectedPatient,
  useSelectedQuote,
  useSession,
  useShowErrorPopup,
  useSignUrl,
  useUserSignData,
} from '../../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import RegularSpinner from '../../Spinner/RegularSpinner';
import { SignatureIframe } from '../../../components';

const cx = classNames.bind(styles);

export const SignForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, reportId } = useParams();
  const { getTemplateInfo, isLoading: isLoadingTemplateInfo } = useGetTemplateInfo();
  const { getSignUrl, signUrl, isLoading: isLoadingSignUrl } = useSignUrl();
  const { getDocumentInfo } = useGetDocumentInfo();
  const { showErrorPopup } = useShowErrorPopup();
  const { selectedPatient } = useSelectedPatient();
  const { saveUserSignDataAsync, userSignData } = useUserSignData();
  const { userInfo, refreshUser } = useSession();
  const { selectedQuote } = useSelectedQuote();
  const { setUserInfo } = useSession();

  const [hideDocument, setHideDocument] = useState<boolean>(true);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [textToShow, setTextToShow] = useState<string>('establishConnection');
  const [getData, setGetData] = useState<boolean>(true);
  let timer: any;
  const isLoadingSignForm = isLoadingTemplateInfo || isLoadingSignUrl;

  const dataToHoldRef = useRef({
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    signedAuthorizations: selectedPatient.dealDetails.Autorizaciones_Firmadas,
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: signUrl?.docId,
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: signUrl?.reqId,
  });

  dataToHoldRef.current = {
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    signedAuthorizations: selectedPatient.dealDetails.Autorizaciones_Firmadas,
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: signUrl?.docId,
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: signUrl?.reqId,
  };

  // useEffect(() => {
  //   if (isAllSignDataAvailable) {
  //     fetchData(userSignData);
  //   }
  // }, []);

  const fetchData = async (signUserData: UserSignData) => {
    getTemplateInfo(
      {
        docId: process.env.REACT_APP_TO_SIGN_TEMPLATE_ID,
      },
      {
        onError: () => {
          showErrorPopup('errors.signRequestFailed');
          navigate(`/informeDetallado/${patientId}/${reportId}/confirmation`);
        },
        onSuccess: async templateData => {
          const dataToSend: { templates: SignTemplate } = {
            templates: {
              field_data: {
                field_text_data: {
                  ['Tutor Full Name']: signUserData.tutorFullName,
                  ['Tutor DNI']: signUserData.tutorId,
                  ['Person Name']: selectedPatient.title,
                  ['Residency Name']: selectedPatient.residence,
                  ['Patient DNI']: signUserData.patientCountryId,
                },
              },
              actions: [
                {
                  ...templateData.templates.actions[0],
                  recipient_name: selectedPatient.title,
                  recipient_email: signUserData.email ?? '',
                  verify_recipient: false,
                  private_notes: '',
                  action_id: templateData.templates.actions[0].action_id,
                  is_embedded: true,
                },
              ],
              notes: '',
            },
          };
          delete dataToSend.templates.actions?.[0].fields;
          getSignUrl(
            {
              data: dataToSend,
              templateId: process.env.REACT_APP_TO_SIGN_TEMPLATE_ID,
            },
            {
              onError: () => {
                showErrorPopup('errors.signRequestFailed');
                navigate(`/informeDetallado/${patientId}/${reportId}/confirmation`);
              },
              onSuccess: () => {
                setHideDocument(false);
                if (!timer) {
                  timer = setInterval(getDocumentSignState, 2500);
                }
              },
            },
          );
        },
      },
    );
  };

  const stopInterval = () => {
    clearInterval(timer);
    timer = null;
  };

  const getDocumentSignState = () => {
    if (dataToHoldRef.current.docId == null || dataToHoldRef.current.reqId == null) {
      return;
    }

    getDocumentInfo(
      { reqId: dataToHoldRef.current.reqId as string },
      {
        onSuccess: documentInfo => {
          if (documentInfo.requests.request_status !== 'completed' || !getData) return;

          setGetData(false);
          stopInterval();
          setTextToShow('savingDoc');
          navigate(`/informeDetallado/${patientId}/${reportId}/paymentMethod`);
        },
      },
    );
  };

  const form = useForm({
    defaultValues: {
      email: userSignData.email,
      tutorFullName: userSignData.tutorFullName,
      tutorId: userSignData.tutorId,
      patientCountryId: userSignData.patientCountryId,
      patientId: userSignData.patientId,
    },
    onSubmit: async values => {
      try {
        const userDataResponse = (await saveUserSignDataAsync({
          patientId: userSignData.patientId,
          tutorId: userInfo?.id ?? 0,
          quoteId: selectedQuote?.id ?? '',
          data: {
            patientId: userSignData.patientId,
            email: values.value.email,
            tutorFullName: values.value.tutorFullName,
            tutorId: values.value.tutorId,
            patientCountryId: values.value.patientCountryId,
          },
        })) as UserSignData;
        const stripeId = userDataResponse.stripeId;
        if (userInfo) {
          setUserInfo({
            ...userInfo,
            stripeId: stripeId as string,
          });
        }
      } catch (err) {
        console.error('SignForm - FormSubmit(): ', err);
      }
      await refreshUser();
      if (selectedPatient.dealDetails.Archivo_Consentimientos?.length ?? 0 === 0) {
        fetchData(values.value);
      } else {
        navigate(`/informeDetallado/${patientId}/${reportId}/paymentMethod`);
      }
    },
  });

  // return hideDocument && !isAllSignDataAvailable ? (
  return hideDocument && !isLoadingSignForm ? (
    <>
      <p className='max-w-xl font-bold'>
        {`${GlobalService.uppercaseAfterPointFirstsLetters(t('pages.pagos.formDescription'))}:`}
      </p>
      <form
        className={cx('sign-form')}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <form.Field
          name={'email'}
          validators={{
            onChange: ({ value }) => {
              const isValid = validate(value, 'email');
              return isValid ? undefined : 'errors.emailInvalid';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            return (
              <SignInput
                id={field.name}
                label={'email'}
                placeholder=''
                type={'text'}
                // type={userSignData.email === '' ? 'text' : 'hidden'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            );
          }}
        />

        <form.Field
          name={'tutorFullName'}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            return (
              <SignInput
                id={field.name}
                label={`${GlobalService.uppercaseFirstLetter(t('pages.pagos.relativeFullName'))}`}
                placeholder=''
                type={'text'}
                // type={userSignData.tutorFullName === '' ? 'text' : 'hidden'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            );
          }}
        />

        <form.Field
          name={'tutorId'}
          validators={{
            onChange: ({ value }) => {
              const isValid = validate(value, 'dni');
              return isValid ? undefined : 'errors.dniInvalid';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            return (
              <SignInput
                id={field.name}
                label={'tutorId'}
                placeholder=''
                type={'text'}
                // type={userSignData.tutorId === '' ? 'text' : 'hidden'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            );
          }}
        />

        <form.Field
          name={'patientCountryId'}
          validators={{
            onChange: ({ value }) => {
              const isValid = validate(value, 'dni');
              return isValid ? undefined : 'errors.dniInvalid';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            return (
              <SignInput
                id={field.name}
                label={'pages.pagos.patientCountryId'}
                placeholder=''
                type={'text'}
                // type={userSignData.patientCountryId === '' ? 'text' : 'hidden'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            );
          }}
        />

        <form.Subscribe
          // @ts-ignore
          selector={state => [state.canSubmit, state.isSubmitting]}
          // @ts-ignore
          // eslint-disable-next-line react/no-children-prop
          children={([canSubmit, isSubmitting]) => (
            <Button
              styleType='primary'
              disabled={!canSubmit}
              fullWidth={true}
              type='submit'
              isLoading={isSubmitting}
            >
              {GlobalService.uppercaseFirstLetter(t('pages.pagos.formButtonLabel'))}
            </Button>
          )}
        />
      </form>
    </>
  ) : (
    <>
      {signUrl?.url ? (
        <p className='max-w-xl font-bold'>
          {`${GlobalService.uppercaseAfterPointFirstsLetters(t('pages.pagos.payTextInfo'))}`}
        </p>
      ) : null}
      <div className={cx('iframe-container')}>
        {showSpinner ? <RegularSpinner /> : null}
        {signUrl?.url ? (
          <SignatureIframe url={signUrl.url} onLoad={() => setShowSpinner(false)} />
        ) : (
          <p className='text-center max-w-xl font-bold mt-4'>
            {GlobalService.uppercaseFirstLetter(t(`pages.signature.${textToShow}`))}
          </p>
        )}
        {isLoadingSignForm ? <Loader /> : null}
      </div>
    </>
  );
};
