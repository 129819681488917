import { useAtomValue } from 'jotai';
import { resetPasswordAtom } from '../../../store/global/atoms/user-session/reset-password-atoms';

export const useResetPassword = () => {
  const resetPassword = useAtomValue(resetPasswordAtom);
  
    return {
      resetPasswordData: resetPassword.data,
      resetPassword: resetPassword.mutate,
      resetPasswordAsync: resetPassword.mutateAsync,
      error: resetPassword.error,
      isLoading: resetPassword.isPending,
      clear: resetPassword.reset,
    };
}

