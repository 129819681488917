import axios from 'axios';
import type { ApiResponse, TutorInfo, UpdateUserObject } from '../../ts';

interface PrismaUpdateErrorData {
  code?: string;
  target?: string[];
}

type UpdateApiResponse = ApiResponse<TutorInfo, PrismaUpdateErrorData>;

const FIELD_NAME_TO_ERROR = {
  phone: 'errors.alreadyInUse_Mobile',
  email: 'errors.alreadyInUse_Email',
};

export const updateUserService = async (newUser: UpdateUserObject): Promise<TutorInfo> => {
  const newUserCopy: TutorInfo = { ...newUser };
  delete newUserCopy.id;

  let response: UpdateApiResponse;
  try {
    const axiosResponse = await axios.put<UpdateApiResponse>('/users/me', newUserCopy);

    response = axiosResponse.data;
  } catch (error: any) {
    // console.error('error.message', error.message);
    throw new Error(error.message);
  }


  if (response.isOk !== true) {
    // console.log('[updateUserService] response', response);

    if (response.message.toLowerCase() === 'invalid sms code') {
      throw new Error('modal.verifyMobile.errors.invalidSmsCode');
    }

    if (response.data?.code === 'P2002') {
      const targets = response.data.target ?? [];

      const errors = targets.map(
        target => FIELD_NAME_TO_ERROR[target as keyof typeof FIELD_NAME_TO_ERROR],
      );

      if (errors.length > 0) {
        throw new Error(errors.join('|||'));
      }
    }

    throw new Error('errors.updateInvalid');
  }

  return response.data;
};
