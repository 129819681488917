import axios from 'axios';
import { ApiResponse } from '../../../ts';
import { CloneQuoteParams } from 'src/ts/interfaces/quotes/clone-quote-params';

export const cloneQuoteService = async ({
  quoteId, subject, stage, reportType, acceptedAmount, payment1, numberOfPayments, quotedItems
}: CloneQuoteParams) => {
  const response = await axios.post<ApiResponse<any>>(
    '/patients/clone-quote',
    { quoteId, subject, stage, reportType, acceptedAmount, payment1, numberOfPayments, quotedItems },
  );

  const { data } = response;

  if (data.isOk !== true) {
    throw new Error('Error setting audio as listened');
  }

  return data.data;
};
