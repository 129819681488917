import styles from './confirm-payment-method-popup.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  useSelectedPatient,
  useSelectedQuote,
  useSetPopup,
  useSetUrlToReturn,
  useUpdateDeal,
  useUpdateQuote,
} from '../../../hooks';
import { FC } from 'react';
import { DealPaymentsToSend, IPatientDeal } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IPersona';
import { dealPaymentOptions, dealPaymentTypes } from '../../../consts';

const cx = classNames.bind(styles);

interface ConfirmPaymentMethodPopupProps {
  onSuccessCallback?: () => void;
  dealDetails: Omit<IPatientDeal, "Contact_Name" | "patient">;
  paymentTotalAmount: number;
}

export const ConfirmPaymentMethodPopup: FC<ConfirmPaymentMethodPopupProps> = ({
  onSuccessCallback = () => {}, dealDetails, paymentTotalAmount,
}) => {
  const { t } = useTranslation();
  const { selectedQuote, removeSelectedQuote } = useSelectedQuote();
  const { updateDeal } = useUpdateDeal();
  const { selectedPatient, removeSelectedPatient } = useSelectedPatient();
  const { updateQuote, isLoading: isUpdatingPaymentType } = useUpdateQuote();
  const { setUrlToReturn } = useSetUrlToReturn();
  const navigate = useNavigate();
  const { closePopup, setForceKeepOpen } = useSetPopup();

  const handleSelectPayMethod = async () => {
    if (selectedQuote?.id) {
      setForceKeepOpen.enable();
      updateDeal.transfer(dealDetails?.id, dealDetails?.Deal_Name, {
        App_Consumer_Payment_Managed: true,
        Pago_Inicial: false,
        Pago_Final: false,
        Tipo_de_Pago: dealPaymentTypes.TRANSFER,
        Pagos: [
            {
              Cobrado: dealPaymentOptions.NOT_PAID,
              Fecha_Pago: '',
              M_todo_de_Pago: dealPaymentTypes.TRANSFER,
              Pago: paymentTotalAmount,
              Observaciones: dealPaymentOptions.OBSERVATIONS,
            }
        ] as unknown as DealPaymentsToSend[],
      });
      updateQuote.transfer(selectedQuote.id, [], paymentTotalAmount, {
        onSuccess: () => {
          onSuccessCallback();
          setUrlToReturn(`/informes/${selectedPatient.id}`);
          setTimeout(() => {
            removeSelectedQuote();
            removeSelectedPatient();
            navigate('/personas');
          }, 6000);
        },
        onSettled: () => {
          setForceKeepOpen.disable();
          closePopup();
        },
      });
    }
  };

  return (
    <div className={cx('container')}>
      <p>
        {GlobalService.uppercaseJustFirstLetter(
          t('modal.transferencia.confirmPaymentMethodModalBody'),
        )}
      </p>

      <div className={cx('buttons-container')}>
        <Button styleType='secondary' onClick={() => closePopup()} fullWidth={true}>
          {t('cancel')}
        </Button>

        <Button onClick={handleSelectPayMethod} isLoading={isUpdatingPaymentType} fullWidth={true}>
          {t('confirm')}
        </Button>
      </div>
    </div>
  );
};
