import { useAtomValue } from 'jotai';
import { MutateOptions } from '@tanstack/query-core';
import { updateDealAtom } from '../../../store/global/atoms';
import { UpdateDealOptions } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IPersona';
import { UpdateDealParams } from 'src/ts';
import { ApiResponse } from '../../../ts';

type Options = MutateOptions<ApiResponse<any>, Error, UpdateDealParams>;

export const useUpdateDeal = () => {
  const updateDealBase = useAtomValue(updateDealAtom);
  
  const updateDeal = {
    transfer: (dealId: string, dealName: string, dealOptions: UpdateDealOptions, options?: Options) =>
      updateDealBase.mutate({ dealId, dealName, dealOptions }, options),

    receipt: (dealId: string, dealName: string, dealOptions: UpdateDealOptions, options?: Options) =>
      updateDealBase.mutate({ dealId, dealName, dealOptions }, options),

    card: (dealId: string, dealName: string, dealOptions: UpdateDealOptions, options?: Options) =>
      updateDealBase.mutate({ dealId, dealName, dealOptions }, options),
  };

  return {
    updateDealResult: updateDealBase.data,
    updateDeal,
    error: updateDealBase.error,
    isLoading: updateDealBase.isPending,
  };
};
