import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  useConfirmPaymentMethodPopup,
  useSelectedPatientValue,
  useSelectedQuoteValue,
  useSettings,
  useViewport,
} from '../../hooks';
import { useSessionValue } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { tiendaRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { AVAILABLE_SETTINGS } from '../../consts/settings/available-settings';
import { Button } from '../../components/Buttons/button';
import classNames from 'classnames/bind';
import styles from './transfer-payment.module.css';
import { GoBackArrow, TopMenu } from '../../components';

const cx = classNames.bind(styles);

const TransferPayment = () => {
  const { patientId, reportId } = useParams();
  const { viewportWidth } = useViewport();
  const { selectedPatient } = useSelectedPatientValue();
  const { userInfo } = useSessionValue();
  const { t } = useTranslation();
  const { selectedQuote } = useSelectedQuoteValue();
  const { getSettingKey } = useSettings();
  const { showConfirmPaymentMethodPopup } = useConfirmPaymentMethodPopup();

  const [showMailSended, setShowMailSended] = useState<boolean>(false);
  const [isMailSent, setIsMailSended] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const tutorName = userInfo?.name;
  const isScreenBig = viewportWidth > 768;
  const textBase = 'pages.informeDetallado';

  let mail = getSettingKey(AVAILABLE_SETTINGS.DOCUMENTS);
  mail = mail.substring(mail.indexOf(':') + 1);
  const dataToHold = {
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: '',
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: '',
  };
  const account = getSettingKey(AVAILABLE_SETTINGS.TRANSFER_ACC);
  const total = selectedQuote?.Grand_Total;

  const handleOnClickEmail = () => {
    if (!isMailSent) {
      console.log('Sending email');

      const salut = `${GlobalService.uppercaseFirstLetter(t('modal.transferencia.salut'))} ${
        dataToHold.patientName
      }`;
      const subject = `${GlobalService.uppercaseFirstLetter(t('modal.transferencia.subject'))} ${
        dataToHold.patientName
      }`;

      const body = `
        <div style="font-family: Arial, sans-serif;">
        <div style="margin-bottom: 20px;">
            <p style="font-weight: 700; font-size: larger;">${GlobalService.uppercaseFirstLetter(
              t('modal.transferencia.titleEmailTransfer'),
            )}</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 700;  color: #EB6D81;">${GlobalService.uppercaseFirstLetter(
              t('modal.transferencia.accountHolder'),
            )}</p>
            <p style="background-color: #f4f4f4; padding: 10px; border-radius: 5px;">LURA CARE (DENTAL RESIDENCY)</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 700;  color: #EB6D81;">${GlobalService.uppercaseFirstLetter(
              t('modal.transferencia.accountNum'),
            )}</p>
            <p style="background-color: #f4f4f4; padding: 10px; border-radius: 5px;">${getSettingKey(
              AVAILABLE_SETTINGS.TRANSFER_ACC,
            )}</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 700;  color: #EB6D81;">${GlobalService.uppercaseFirstLetter(
              t('modal.transferencia.amount'),
            )}</p>
            <p style="background-color: #f4f4f4; padding: 10px; border-radius: 5px;">${total} €</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 700;  color: #EB6D81;">${GlobalService.uppercaseFirstLetter(
              t('modal.transferencia.concept'),
            )}</p>
            <p style="background-color: #f4f4f4; padding: 10px; border-radius: 5px;">${
              selectedPatient.title
            }</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 400; color: #4a4a4a;">${GlobalService.uppercaseFirstLetter(
              t('modal.transferencia.bodyText'),
            )} ${mail}</p>
          </div>
          <div>
            <p style="font-weight: 700; color: #4a4a4a;">${GlobalService.uppercaseFirstLetter(
              t('modal.transferencia.thanks'),
            )}</p>
          </div>
        </div>
      `;

      const details = {
        body,
        mail: userInfo?.email ?? '',
        subject,
        salut,
      };

      tiendaServices(tiendaRepositoryInstance)
        .sendTransfMail(details)
        .then(() => {
          setIsMailSended(true);
        });
    }
  };

  const backLink = `/informeDetallado/${patientId}/${reportId}/paymentMethod`;

  return (
    <div className={cx('main-container')}>
      <TopMenu
        title={`${GlobalService.uppercaseFirstLetter(t(`${textBase}.mainTitle`))}: ${
          selectedPatient.title
        }`}
        leftIcon={!isScreenBig}
        leftIconLink={backLink}
      />

      {!showMessage && (
        <div className={cx('body-container-main')}>
          {isScreenBig ? (
            <div className={cx('title--container')}>
              <GoBackArrow />

              <h3 className={cx('title--text')}>
                {GlobalService.uppercaseFirstLetter(t(`pages.paymentMethod.paymenttransfer`))}
              </h3>
            </div>
          ) : null}

          <div className={cx('body-container', 'bs-20')}>
            <p>
              {`${t(`modal.transferencia.maketransfer`)} ${selectedQuote?.Grand_Total}€ ${t(
                `modal.transferencia.totheaccount`,
              )}`}
            </p>

            <div className={cx('details-container')}>
              <div>
                <p className={cx('title')}>
                  <strong>
                    {GlobalService.uppercaseFirstLetter(t('modal.transferencia.accountHolder'))}
                  </strong>
                </p>
                <p>LURA CARE (DENTAL RESIDENCY)</p>
              </div>

              <div>
                <p className={cx('title')}>
                  <strong>
                    {GlobalService.uppercaseFirstLetter(t('modal.transferencia.accountNum'))}
                  </strong>
                </p>
                <p>{account}</p>
              </div>

              <div>
                <p className='font-bold'>
                  <strong className={cx('title')}>
                    {GlobalService.uppercaseFirstLetter(t('modal.transferencia.amount'))}
                  </strong>
                </p>
                <p>{total} €</p>
              </div>

              <p className={cx('title')}>
                <strong>
                  {GlobalService.uppercaseFirstLetter(t('modal.transferencia.concept'))}
                </strong>
              </p>
              <p>{selectedPatient.title}</p>
            </div>

            <p>
              {GlobalService.uppercaseFirstLetter(t('modal.transferencia.bodyText'))}{' '}
              <span className={cx('text-red')}>{mail}</span>
            </p>
            <p>{GlobalService.uppercaseFirstLetter(t('modal.transferencia.thanks'))}</p>

            <div className={cx('buttons-container')}>
              <Button
                onClick={() => {
                  setShowMailSended(true);
                  handleOnClickEmail();
                }}
                fullWidth={true}
              >
                {GlobalService.uppercaseJustFirstLetter(t('modal.transferencia.sendinfo'))}
              </Button>

              <Button
                onClick={() => showConfirmPaymentMethodPopup(() => setShowMessage(true), selectedPatient.dealDetails, selectedQuote?.Grand_Total ?? 0)}
                fullWidth={true}
              >
                {GlobalService.uppercaseJustFirstLetter(
                  t('modal.transferencia.confirmPaymentMethodModalTitle'),
                )}
              </Button>
            </div>
            {isMailSent ? (
              <div className='mt-8' style={{ color: 'green' }}>
                {GlobalService.uppercaseJustFirstLetter(t('modal.transferencia.mailsended'))}
              </div>
            ) : null}
          </div>
        </div>
      )}
      {showMessage && (
        <div className={cx('message-container')}>
          <div className={cx('message-container')}>
            <p className={cx('message-container-text')}>
              {GlobalService.uppercaseFirstLetter(
                t('modal.transferencia.confirmTransferMethodMessage'),
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransferPayment;
