import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { LoginSignupType } from 'src/ts';
import { useBlindSignupToken, useLoginSignupSwitchLink } from '../../../hooks/';

interface LoginSignupSwitchLinkProps {
  type: LoginSignupType;
}

const typeToText = {
  login: 'pages.login.noAccount',
  signup: 'pages.signup.haveAnAccount'
};

const typeToLinkText = {
  login: 'pages.signup.register',
  signup: 'pages.signup.login'
};

export const LoginSignupSwitchLink: FC<LoginSignupSwitchLinkProps> = ({ type }) => {
  const { t } = useTranslation();
  const { link } = useLoginSignupSwitchLink(type);
  const { verifyPatientBirthDate } = useBlindSignupToken();

  const bstText =
    verifyPatientBirthDate != null && type === 'signup' ? t('pages.signup.loginAddRelative') : '';

  return (
    <>
    <div className='flex flex-row justify-center gap-1 mt-3'>
      <p className='forgot-text'>
        {GlobalService.uppercaseFirstLetter(t(typeToText[type]))}?{` ${bstText}`}{' '}
        <Link to={link} className='forgot-link'>
          {GlobalService.uppercaseFirstLetter(t(typeToLinkText[type]))}
        </Link>
      </p>
    </div>
      <div className='flex flex-row justify-center gap-1 mt-3'>
        <p className='forgot-text'>
        <Link to={'/forgotPassword'} className='flex forgot-link mt-2 justify-end'>
          {GlobalService.uppercaseJustFirstLetter(t('pages.login.forgotPassword'))}
        </Link>
        </p>
      </div>        
    </>
  );
};
