import { QUERY_KEYS } from '../../../../../consts';
import { getAtomWithMutation } from '../../../../../utils';
import { updateDealService } from '../../../../../services';
import { getAllPatientsAtom } from '../../patients-atoms';

export const updateDealAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.DEALS.UPDATE_DEAL,
  mutationFn: () => updateDealService,
  onSuccess: get => () => {
    const getAllPatients = get(getAllPatientsAtom);
    getAllPatients.mutate();
  },
});
