import axios from 'axios';
import type { ApiResponse, updateQuoteParams } from 'src/ts';

export const updateQuoteService = async (data: updateQuoteParams) => {
  let result: ApiResponse<any>;

  try {
    const response = await axios.patch<ApiResponse<any>>('/patients/update-quote', data);
    result = response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }

  if (!result.isOk) {
    throw new Error(result.message);
  }

  return result;
};
