import { signServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/sign.service';
import { DocumentIdentifier } from '../../../ts';
import { signRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/signRepository';

export const getDocumentInfoService = async ({ reqId }: DocumentIdentifier) => {
  const response = await signServices(signRepositoryInstance).getDocInfo(reqId);

  if (typeof response === 'string') throw new Error(response);

  return response.data;
};
