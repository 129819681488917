import axios from 'axios';

export const loginWithTokenService = async (token: string): Promise<any> => {
  const url = `/auth/login-with-token/${token}`;
  let response;
  try {
    response = await axios.get(url);
  } catch (error: any) {
    throw new Error(error);
  }
  console.log('loginWithTokenService', response.data.data);
  return response.data.data;
};
