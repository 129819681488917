import { atomWithStorage, selectAtom } from 'jotai/vanilla/utils';
import { LOCAL_STORAGE_KEYS, SMS_LIMIT_PER_BLOCK } from '../../../../consts';
import { MobileVerificationTimestamps } from '../../../../ts';
import { atom } from 'jotai';

export const mobileVerificationTimestampsAtom =
  atomWithStorage<MobileVerificationTimestamps | null>(
    LOCAL_STORAGE_KEYS.MOBILE_VERIFICATION_TIMESTAMPS,
    null,
    undefined,
    { getOnInit: true },
  );

export const lastSMSTimestampAtom = selectAtom(
  mobileVerificationTimestampsAtom,
  data => data?.lastSMSTimestamp ?? null,
  (prevVal, newVal) => prevVal === newVal,
);

export const firstSMSTimestampAtom = selectAtom(
  mobileVerificationTimestampsAtom,
  data => data?.firstSMSTimestamp ?? null,
  (prevVal, newVal) => prevVal === newVal,
);

export const betweenSMSIntervalAtom = atom<NodeJS.Timeout | null>(null);
export const timeLeftForNextSMSAtom = atom(-1);

export const betweenBlocksIntervalAtom = atom<NodeJS.Timeout | null>(null);
export const timeLeftForNextBlockAtom = atom(-1);

export const limitPerBlockReachedAtom = atom(get => {
  const timestamps = get(mobileVerificationTimestampsAtom);

  if (timestamps === null) return false;

  const timeLeftForNextBlock = get(timeLeftForNextBlockAtom);
  const { SMSCountSinceFirst } = timestamps;

  return timeLeftForNextBlock > 0 && SMSCountSinceFirst >= SMS_LIMIT_PER_BLOCK;
});

export const canResendSMSAtom = atom(get => {
  const timeLeftForNextBlock = get(timeLeftForNextBlockAtom);
  const timeLeftForNextSMS = get(timeLeftForNextSMSAtom);
  const limitPerBlockReached = get(limitPerBlockReachedAtom);

  return (timeLeftForNextBlock <= 0 || !limitPerBlockReached) && timeLeftForNextSMS <= 0;
});
